<template>
  <v-select
    :label='$t("model.name.series")'
    class="white"
    :items="data"
    :item-text="named"
    item-value="id"
    v-model="inputVal"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError","value"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.url =`${this.$api.servers.event}/api/v1/en/moderator/data/series`
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
    //EOC
  },
   computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
    },
    named: (item) => item.name + " - " + item.countryCode,
  },
  mounted() {
    this.$api.fetch(this.api);
  },
};
</script>
